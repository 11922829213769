// ================================================
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Tooltip } from "react-tooltip";

import { ContextSidebarToggler } from "../../../Context/SidebarToggler/SidebarToggler";
import {
  addIcon,
  clientsIcon1,
  subtractIcon,
} from "../../../utils/ImportingImages/ImportingImages";
import { SpinningLoader } from "../../../Components/SpinningLoader/SpinningLoader";
import { ContextAPI } from "../../../Context/ApiContext/ApiContext";
import { ReactHotToast } from "../../../Components/ReactHotToast/ReactHotToast";
import Breadcrumbs from "../../../templates/Breadcrumbs";
import Select from "react-select";

import {
  handleAPIError,
  headerOptions,
} from "../../../utils/utilities/utilityFunctions";
import { useNavigate } from "react-router-dom";

const AddClientsContent = () => {
  const {
    mainURL,
    logout,
    getAllJobCategories,
    userDetails,
    initialState,
    getAllBillingServices,
  } = useContext(ContextAPI);
  const { sidebarClose } = useContext(ContextSidebarToggler);
  const [billingServiceName, setBillingServiceName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [options, setOptions] = useState({ billingServicesOptions: [] });
  const navigate = useNavigate();

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    companyName: "",
    email: "",
    phone: "",
    businessAddress: "",
    consultant: "",
    comment: "",
    bpoNumber: "",
    billingRate: 0,
    accountingHead: "",
    billingServices: [], // Store billing services here
  });

  useEffect(() => {
    getAllJobCategories();
    getAllBillingServices();
  }, [isUpdated]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      billingServicesOptions: initialState?.billingServicesList
        ?.filter(({ service_status }) => service_status === "active")
        .map(({ services_name, services_id }) => ({
          label: services_name,
          value: services_id,
        })),
    }));
  }, [initialState.billingServicesList]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleServiceChange = (option, index) => {
    const updatedServices = clientDetails.billingServices.map((service, i) =>
      i === index ? { ...service, service_id: option?.value || "" } : service
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
    setSelectedServices(
      updatedServices.map((s) => s.service_id).filter((id) => id)
    );
  };

  const handleRateChange = (index, rate) => {
    const updatedServices = clientDetails.billingServices.map((service, i) =>
      i === index ? { ...service, service_rate: rate } : service
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
  };

  const addBillingService = () => {
    setClientDetails((prev) => ({
      ...prev,
      billingServices: [
        ...(prev.billingServices || []),
        { service_id: "", service_rate: "" },
      ],
    }));
  };

  const removeBillingService = (index) => {
    const updatedServices = (clientDetails.billingServices || []).filter(
      (_, i) => i !== index
    );
    setClientDetails((prev) => ({
      ...prev,
      billingServices: updatedServices,
    }));
  };

  const addNewBillingService = async () => {
    const body = {
      billing_services: billingServiceName,
      current_user:
        localStorage.getItem("userId") ?? userDetails?.member_id ?? null,
    };

    setIsLoading(() => true);
    // console.log("bodyNew Service", body);

    try {
      const url = `${mainURL}/services/billing`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setBillingServiceName(() => "");
        setIsUpdated((prev) => !prev);
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const addNewClient = async () => {
    const body = {
      current_user: localStorage.getItem("userId") || userDetails?.member_id,
      client_name: clientDetails.clientName,
      company_name: clientDetails.companyName,
      email: clientDetails.email,
      contact_no: clientDetails.phone,
      billing_address: clientDetails.businessAddress,
      consultant: clientDetails.consultant,
      comment: clientDetails.comment,
      bpo_no: clientDetails.bpoNumber,
      billing_rates: clientDetails.billingRate,
      accounting_head: clientDetails.accountingHead,
      billing_services: clientDetails.billingServices,
    };
    // console.log("bodyAddClient", body);
    setIsLoading(() => true);

    try {
      const url = `${mainURL}/add-new/client`;
      const result = await axios.post(url, body, {
        headers: headerOptions(),
      });
      if (result.status === 201) {
        ReactHotToast(result?.data?.message, "success");
        setClientDetails({
          clientName: "",
          companyName: "",
          email: "",
          phone: "",
          businessAddress: "",
          consultant: "",
          comment: "",
          bpoNumber: "",
          billingRate: 0,
          accountingHead: "",
          billingServices: [],
        });
        navigate("/clients");
      }
    } catch (e) {
      handleAPIError(e, logout);
    } finally {
      setIsLoading(() => false);
    }
  };

  const handleNewClient = (e) => {
    e.preventDefault();

    const {
      clientName,
      companyName,
      email,
      phone,
      businessAddress,
      bpoNumber,
      comment,
      consultant,
      accountingHead,
    } = clientDetails;

    // Regex to ensure no double quotes in the input fields
    const regex = /^[^"]*$/;
    const emailRegex =
      /^(?![._%+-])[a-zA-Z0-9._%+-]+[a-zA-Z0-9]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    const PhoneRegex = /^\d{7,15}$/; // Allows 7-15 digit numbers
    // Validate fields using regex (no double quotes allowed)
    const isCompanyNameValid = regex.test(companyName);
    const isBpoNumberValid = regex.test(bpoNumber);
    const isBusinessAddressValid = regex.test(businessAddress);
    const isCommentValid = regex.test(comment);
    const isConsultantValid = regex.test(consultant);
    const isAccountingHeadValid = regex.test(accountingHead);
    const isEmailValid = emailRegex.test(email) && !email.includes("'");
    const isPhoneValid = PhoneRegex.test(phone) && !phone.includes("'");

    // Error conditions for required fields and regex validation
    const conditions = [
      { condition: !clientName, message: "Please input client name!" },
      { condition: !companyName, message: "Please input company name!" },
      { condition: !email, message: "Please input client email!" },
      { condition: !phone, message: "Please input client contact!" },
      { condition: !businessAddress, message: "Please input client address!" },
      { condition: !bpoNumber, message: "Please input client's BPO number!" },
      {
        condition: !isCompanyNameValid,
        message: "Company name cannot contain double quotes!",
      },
      {
        condition: !isBpoNumberValid,
        message: "BPO number cannot contain double quotes!",
      },
      {
        condition: !isBusinessAddressValid,
        message: "Business address cannot contain double quotes!",
      },
      {
        condition: !isCommentValid,
        message: "Comments cannot contain double quotes!",
      },
      {
        condition: !isConsultantValid,
        message: "Consultant name cannot contain double quotes!",
      },
      {
        condition: !isAccountingHeadValid,
        message: "Accounting head name cannot contain double quotes!",
      },
      {
        condition: !isEmailValid,
        message: "Invalid email format!",
      },
      {
        condition: !isPhoneValid,
        message: "Please input Phone Number correctly !",
      },
    ];

    // Check if any condition is met (if so, show the corresponding error message)
    const error = conditions.find((item) => item.condition);

    if (error) {
      ReactHotToast(error.message, "error");
    } else {
      // If everything is valid, call addNewClient
      addNewClient();
    }
  };

  // const handleNewClient = (e) => {
  //   e.preventDefault();
  //   const {
  //     companyName,
  //     bpoNumber,
  //     businessAddress,
  //     comment,
  //     consultant,
  //     accountingHead,
  //   } = clientDetails;

  //   const regex = /^[^"]*$/;

  //   const isCompanyNameValid = regex.test(companyName);
  //   const isBpoNumber = regex.test(bpoNumber);
  //   const isBillingAddressValid = regex.test(businessAddress);
  //   const isAdditionalCommentsValid = regex.test(comment);
  //   const isConsultantValid = regex.test(consultant);
  //   const isAccountingHeadValid = regex.test(accountingHead);

  //   const bool =
  //     companyName &&
  //     bpoNumber &&
  //     businessAddress &&
  //     comment &&
  //     consultant &&
  //     accountingHead &&
  //     isCompanyNameValid &&
  //     isBillingAddressValid &&
  //     isAdditionalCommentsValid &&
  //     isConsultantValid &&
  //     isAccountingHeadValid;

  //   const conditions = {
  //     [!companyName]: "Please input company name!",
  //     [!isBpoNumber]: "Bpo Number cannot contain double quotes!",
  //     [!businessAddress]: "Please input billing address!",
  //     [!comment]: "Please input additional comments!",
  //     [!consultant]: "Please input consultant name!",
  //     [!accountingHead]: "Please input accounting head name!",
  //     [!isCompanyNameValid]: "Company name cannot contain double quotes!",
  //     // [!isPrimaryBillingAddressValid]: "Primary billing address cannot contain double quotes!",
  //     [!isBillingAddressValid]: "Business address cannot contain double quotes!",
  //     [!isAdditionalCommentsValid]: "comments cannot contain double quotes!",
  //     [!isConsultantValid]: "Consultant name cannot contain double quotes!",
  //     [!isAccountingHeadValid]: "Accounting head name cannot contain double quotes!",
  //   };

  //   const errorMessage = conditions[true];
  //   if (errorMessage) {
  //     ReactHotToast(errorMessage, "error");
  //   } else {
  //     addNewClient();
  //   }
  // };

  // const handleNewClient = (e) => {
  //   e.preventDefault();
  //   const {
  //     clientName,
  //     companyName,
  //     email,
  //     phone,
  //     businessAddress,
  //     bpoNumber,
  //   } = clientDetails;

  //   const bool =
  //     clientName &&
  //     companyName &&
  //     email &&
  //     phone &&
  //     businessAddress &&
  //     bpoNumber;

  //   if (bool) {
  //     addNewClient();
  //   } else {
  //     const conditions = {
  //       [!businessAddress]: "Please input client address!",
  //       [!phone]: "Please input client contact!",
  //       [!email]: "Please input client email!",
  //       [!companyName]: "Please input company name!",
  //       [!bpoNumber]: "Please input client's BPO number!",
  //       [!clientName]: "Please input client name!",
  //     };

  //     const errorMessage = conditions[true];
  //     if (errorMessage) {
  //       ReactHotToast(errorMessage, "error");
  //     }
  //   }
  // };

  const handleAddBillingService = (e) => {
    e.preventDefault();
    if (billingServiceName) {
      addNewBillingService();
    } else {
      ReactHotToast("Please add billing service name!", "error");
    }
  };

  const getOptionsForIndex = (index) => {
    const selectedServiceIds = clientDetails.billingServices
      .filter((_, i) => i !== index)
      .map((service) => service.service_id);
    return options.billingServicesOptions.filter(
      (option) => !selectedServiceIds.includes(option.value)
    );
  };

  const breadCrumbs = [
    {
      pageName: "Home",
      pageURL: "/clients",
    },
    {
      pageName: "Clients",
      pageURL: "/clients",
    },
    {
      pageName: "Add Client",
      pageURL: "/add-client",
    },
  ];

  return (
    <div className={`main-content ${sidebarClose ? "sidebarClose" : ""}`}>
      <div className="mr-40 ml-30 mb-15">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="main-content_header">
        <div className="d-flex justify-content-start align-items-center page-heading w-100 custom-border-bottom">
          <img src={clientsIcon1} alt="clients" />
          <p className="m-0 fs-4">Add Clients</p>
        </div>
      </section>

      <section className="main-content_form-section gap-5 d-flex align-items-start w-75 m-auto">
        <form onSubmit={handleNewClient} className="w-50">
          <div className="form-group mt-5">
            <label htmlFor="clientName">Client Name:</label>
            <input
              id="clientName"
              name="clientName"
              placeholder="Eg: Raj Shah"
              type="text"
              // required
              value={clientDetails.clientName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="companyName">Company Name:</label>
            <input
              id="companyName"
              name="companyName"
              placeholder="Eg: Raj Industries"
              type="text"
              // required
              value={clientDetails.companyName}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="bpoNumber">Client BPO No:</label>
            <input
              id="bpoNumber"
              name="bpoNumber"
              placeholder="Eg: DO101..."
              max={10}
              type="text"
              value={clientDetails?.bpoNumber}
              onChange={handleChange}
              // required
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="email">Primary Email Id:</label>
            <input
              id="email"
              name="email"
              placeholder="Eg: rajshah@gmail.com"
              type="email"
              // required
              value={clientDetails.email}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="phone">Phone Number:</label>
            <input
              id="phone"
              name="phone"
              placeholder="Eg: 0000 0000"
              type="number"
              // required
              value={clientDetails.phone}
              onChange={handleChange}
            />
          </div>

          {/* <div className="form-group mt-3">
            <label htmlFor="billingRate">Billing Rate:</label>
            <input
              id="billingRate"
              name="billingRate"
              placeholder="Eg: £ 150"
              type="number"
              value={clientDetails.billingRate}
              onChange={handleChange}
            />
          </div> */}

          <div className="form-group mt-3">
            <label htmlFor="businessAddress">Business Address:</label>
            <input
              id="businessAddress"
              name="businessAddress"
              type="text"
              // required
              placeholder="Eg. A-204, XYZ, M G Road, Vadodra 400500"
              value={clientDetails.businessAddress}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="consultant">Consultant:</label>
            <input
              id="consultant"
              name="consultant"
              type="text"
              placeholder="Eg: Michael Smith"
              value={clientDetails.consultant}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="accountingHead">Accounting Head:</label>
            <input
              id="accountingHead"
              name="accountingHead"
              type="text"
              placeholder="Eg: John Doe"
              value={clientDetails.accountingHead}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="comment">Comment (optional):</label>
            <input
              id="comment"
              name="comment"
              type="text"
              placeholder="Eg: Important client"
              value={clientDetails.comment}
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="mt-3 custom-btn d-flex ">
            {isLoading ? <SpinningLoader /> : "Save"}
          </button>
        </form>

        {/* Billing Service and Rate Section */}
        <div className="w-50">
          <section className="">
            <form
              onSubmit={handleAddBillingService}
              className="d-flex justify-content-between align-items-end gap-3"
            >
              <div className="form-group mt-5 w-100">
                <label htmlFor="name">Service Name:</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Eg: ITR Filling"
                  type="text"
                  required
                  value={billingServiceName}
                  onChange={(e) => setBillingServiceName(e.target.value)}
                />
              </div>
              <button type="submit" className="service-btn">
                {isLoading ? <SpinningLoader /> : "Add Service"}
              </button>
            </form>
          </section>
          {clientDetails.billingServices.length === 0 ? (
            <div className="d-flex gap-3 align-items-center">
              <div className="form-group mt-3 w-100">
                <label>Billing Service:</label>
                <Select
                  isClearable
                  id="billingService-0"
                  name="billingService-0"
                  closeMenuOnSelect={true}
                  options={getOptionsForIndex(0)}
                  value={null}
                  onChange={(option) => {
                    const updatedServices = [
                      { service_id: option?.value || "", service_rate: "" },
                    ];
                    setClientDetails((prev) => ({
                      ...prev,
                      billingServices: updatedServices,
                    }));
                  }}
                  className="react-select-custom-styling__container"
                  classNamePrefix="react-select-custom-styling"
                />
              </div>
              <div className="form-group mt-3 w-100">
                <label htmlFor="billingRate-0">Billing Rate (per hour):</label>
                <input
                  id="billingRate-0"
                  name="billingRate-0"
                  placeholder="Eg: £100"
                  type="number"
                  value=""
                  onChange={(e) => handleRateChange(0, e.target.value)}
                />
              </div>
            </div>
          ) : (
            clientDetails.billingServices.map((service, index) => (
              <div key={index} className="d-flex gap-3 align-items-center">
                <div className="form-group mt-3 w-100">
                  <label>Billing Service:</label>
                  <Select
                    isClearable
                    id={`billingService-${index}`}
                    name={`billingService-${index}`}
                    closeMenuOnSelect={true}
                    options={getOptionsForIndex(index)}
                    value={
                      getOptionsForIndex(index).find(
                        (opt) => opt.value === String(service.service_id)
                      ) || null
                    }
                    onChange={(option) => handleServiceChange(option, index)}
                    className="react-select-custom-styling__container"
                    classNamePrefix="react-select-custom-styling"
                  />
                </div>
                <div className="form-group mt-3 w-100">
                  <label htmlFor={`billingRate-${index}`}>
                    Billing Rate (per hour):
                  </label>
                  <input
                    id={`billingRate-${index}`}
                    name={`billingRate-${index}`}
                    placeholder="Eg: £100"
                    type="number"
                    value={service.service_rate || ""}
                    onChange={(e) => handleRateChange(index, e.target.value)}
                  />
                </div>

                {/* Remove Icon */}
                {clientDetails.billingServices.length > 1 && (
                  <div style={{ marginTop: "32px" }}>
                    <Tooltip
                      id="remove-billing-service-tooltip"
                      style={{
                        background: "#000",
                        color: "#fff",
                      }}
                      opacity={0.9}
                    />
                    <img
                      src={subtractIcon}
                      data-tooltip-id="remove-billing-service-tooltip"
                      data-tooltip-content="Remove Billing Service"
                      data-tooltip-place="top"
                      alt="remove billing service"
                      onClick={() => removeBillingService(index)}
                      className="cursor-pointer"
                    />
                  </div>
                )}
                {/* Add New Billing Service Icon */}
                {clientDetails.billingServices.length - 1 === index &&
                  clientDetails.billingServices.length < 10 && (
                    <div style={{ marginTop: "32px" }}>
                      <Tooltip
                        id="add-billing-service-tooltip"
                        style={{
                          background: "#000",
                          color: "#fff",
                        }}
                        opacity={0.9}
                      />
                      <img
                        src={addIcon}
                        data-tooltip-id="add-billing-service-tooltip"
                        data-tooltip-content="Add Billing Service"
                        data-tooltip-place="top"
                        alt="add billing service"
                        className="cursor-pointer"
                        onClick={addBillingService}
                      />
                    </div>
                  )}
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default AddClientsContent;
