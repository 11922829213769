import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const VersionChecker = () => {
  const [currentVersion, setCurrentVersion] = useState(
    localStorage.getItem("app_version")
  );
  //   const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // let interval;

    const checkVersion = async () => {
      try {
        const response = await fetch("/version.json", { cache: "no-store" });
        const { version } = await response.json();

        if (!currentVersion) {
          handleVersionMissing(version);
        } else if (currentVersion !== version) {
          handleVersionMismatch(version);
        } else {
          localStorage.setItem("app_version", version);
          setCurrentVersion(version);
          localStorage.removeItem("version_mismatch_shown");
        }

        // if (!interval) {
        //   interval = setInterval(checkVersion, 6000);
        // }
      } catch (error) {
        console.error("Version check failed:", error);
      }
    };

    checkVersion();

    // return () => clearInterval(interval);
  }, [currentVersion]);

  const handleVersionMissing = (newVersion) => {
    if (window.location.pathname !== "/") {
      showSweetAlert(newVersion);
    }
  };

  const handleVersionMismatch = (newVersion) => {
    if (
      window.location.pathname !== "/" &&
      localStorage.getItem("version_mismatch_shown") !== "true"
    ) {
      showSweetAlert(newVersion);
    }
  };

  const showSweetAlert = (newVersion) => {
    Swal.fire({
      title:
        '<h3 style="font-size: 1.25rem; line-height: 1.6;">A new version of the application is now available. Please login again to continue using the latest features and improvements.</h3>',
      showCancelButton: false,
      confirmButtonText: "OK",
      confirmButtonColor: "#339989",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: "custom-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        clearSessionData();
        localStorage.setItem("app_version", newVersion);
        localStorage.setItem("version_mismatch_shown", "true");
        window.location.href = "/";
      }
    });
  };

  const clearSessionData = () => {
    const keysToRemove = [
      "auth_token",
      "user_data",
      "userRole",
      "userId",
      "token",
      "todosList",
      "username",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  return null;
};

export default VersionChecker;
